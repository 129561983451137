import React, { useState, useEffect } from 'react';
import { Stack, Box, Text, Input, Button } from '@chakra-ui/react';
import Section from "gatsby-theme-wild-child/src/components/Section"

import axios from "axios"

const ChatSessionsList = () => {
    const [sessions, setSessions] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [sort, setSort] = useState({ created_at: -1 });

    useEffect(() => {
        const fetchSessions = async () => {
            await axios.post(`${process.env.GATSBY_API_BASE_URL}/chat/filter?page=${pageNumber}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    namespace: '',
                    role: '',
                    sessionId: '',
                    sort
                },
            })
                .then((response: any) => {
                    const data = response.data;
                    setSessions(data.response.data);
                    setTotalCount(data.response.meta.totalCount);
                })
                .catch(error => {
                    console.error(error, "nameSpace error")

                });
        };

        fetchSessions();
    }, [pageNumber, searchQuery, sort]);

    const handlePageChange = (newPageNumber) => {
        setPageNumber(newPageNumber);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSort = () => {
        // Toggle the sorting direction when the "Sort" button is clicked
        const newSort = sort.created_at === -1 ? { created_at: 1 } : { created_at: -1 };
        setSort(newSort);
    };

    return (
        <Section mt={8} minH="100vh">
            <Stack spacing={4}>

                <Box mb={4}>
                    <Input
                        placeholder="Search"
                        borderWidth="1px"
                        borderColor="gray.50"
                        borderRadius="md"
                        value={searchQuery}
                        onChange={handleSearch} />
                </Box>

                {sessions.map((item) => (

                    <Box key={item._id} p={4} borderWidth="1px" borderRadius="md">
                        <Text>{item.content}</Text>
                    </Box>
                ))}
            </Stack>
            <Stack>

                <Box mt={4}>
                    <Button onClick={handleSort}>Sort</Button>
                    {pageNumber > 1 && (
                        <Button
                            bg='red.full'
                            mx={2}
                            onClick={() => handlePageChange(pageNumber - 1)}>
                            Previous
                        </Button>
                    )}
                    {pageNumber < totalCount && (
                        <Button
                            bg='red.full'
                            onClick={() => handlePageChange(pageNumber + 1)}>
                            Next
                        </Button>
                    )}
                </Box>
            </Stack>
        </Section>
    );
};

export default ChatSessionsList;