import React from "react"
import ChatSessionsList from "src/gatsby-theme-wild-child/components/chat/history"
import AuthContent from "../gatsby-theme-wild-child/components/auth/AuthContent"

function ChatHistory() {
  return (
    <AuthContent>
      <ChatSessionsList />
    </AuthContent>
  )
}

export default ChatHistory

export const Head = () => (
  <>
    <title>TaxGPT by Virtuzone | Chat History</title>
    <meta
      name="facebook-domain-verification"
      content="0qis6k25boblo98elnosvjz5ws7gtm"
    />
    <meta 
      name="google-site-verification" 
      content="fdkrhMVPDQOPHf3A4czsCkEuBbJ_lkvh8yj01E6fn4Q" 
      />
  </>
)
